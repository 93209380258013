import VendorAnalytics from '../../../decorators/vendorAnalytics';
import { checkIsTablet } from '../containers/InlineRFQ/utils';
import Env from './env';
import NavHelper from './nav_helper';

export interface BuildPropertyProps {
	memberId: string;
	vendor: Vendor.Decorated;
	viewport: Redux.Viewport;
	isBookNow: boolean;
}

const isClaimed = (status: string) => status === 'CLAIMED';

const determineFormat = (isMobile: boolean, isTablet: boolean) => {
	return !isMobile && !isTablet ? 'inline RFQ pricing top' : 'modal';
};

const buildProperties = (props: BuildPropertyProps) => {
	const { vendor, viewport, memberId, isBookNow } = props;
	const { isMobile } = viewport;
	const isTablet = checkIsTablet(viewport);
	const vendorAnalytics = new VendorAnalytics(vendor);

	return {
		...vendorAnalytics.baseEventProps(),
		storefront_completion_score: vendor.completionScore,
		rfqFormat: determineFormat(isMobile, isTablet),
		memberId,
		booking_flag: isBookNow ? 1 : 0,
	};
};

const formatStartingCost = (priceRange: string) =>
	`Starting Cost: ${priceRange}`;

const getPhotographerPricing = (startingPriceRange: string) => {
	if (startingPriceRange) {
		return `Starting Range: ${startingPriceRange.split('-').join(' - ')}`;
	}
	return 'Contact for Pricing';
};

const getPricing = (priceRange: string, catCode: string) => {
	if (priceRange) {
		return formatStartingCost(priceRange);
	}
	return catCode === 'BWP' ? 'Contact for Appointment' : 'Contact for Pricing';
};

const getPricingText = (vendor: Vendor.Decorated) => {
	const { categoryCode, priceRange, startingPriceRange } = vendor;

	if (categoryCode === 'WPH') {
		return getPhotographerPricing(startingPriceRange);
	}
	return getPricing(priceRange, categoryCode);
};

export { NavHelper, Env, buildProperties, getPricingText, isClaimed };
